//const url = 'https://test-pc.fyrentcar.com/' //打包
// const url = 'http://localhost:8080/' //不打包
const url = 'https://www.fyrentcar.com/' //正式线
export default {
    url,
    //   判断为空且是空格
    isNull(str) {
        if (str == "") return true;
        var regu = "^[ ]+$";
        var re = new RegExp(regu);
        return re.test(str);
    },
    //   判断手机号为11位
    isPhone(str) {
        let re = /^1\d{10}$/
        if (re.test(str)) {
            return true;
        } else {
            return false;
        }
    },
    // 判断是否为手机号或者座机号
    isPhoneAndZj(str) {
        let reg = /^1\d{10}$/; //校验手机号和固定电话
        let zj = /^(\d{3,4}-)\d{7,8}$/;
        if (reg.test(str) || zj.test(str)) {
            return true;
        } else {
            return false;
        }
    },
    //   判断密码为6-20位英文、数字
    isPassword(str) {
        var reg = /^([a-zA-Z0-9]){6,20}$/i;
        if (reg.test(str)) {
            return true;
        } else {
            return false;
        }
    },
    // 处理小于10
    lessThanTen(val) {
        return val < 10 ? "0" + val : val;
    },
    // 转化时间 y-m -d
    changeTime(time) {
        let newDate = new Date(time);
        return newDate.getFullYear() + "-" + this.lessThanTen(newDate.getMonth() + 1) + "-" + this.lessThanTen(newDate.getDate())
    },
    formatDate(value) {
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? ('0' + MM) : MM;
        let d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        // let h = date.getHours();
        // h = h < 10 ? ('0' + h) : h;
        // let m = date.getMinutes();
        // m = m < 10 ? ('0' + m) : m;
        // let s = date.getSeconds();
        // s = s < 10 ? ('0' + s) : s;
        return y + '年' + MM + '月' + d + '日';
    },
    strFun(str) {
        if (str.length > 22) {
            str = str.substr(0, 22) + "..."
            return str
        } else {
            return str
        }
    },
    strFunShop(str) {
        if (str.length > 14) {
            str = str.substr(0, 14) + "..."
            return str
        } else {
            return str
        }
    },
    // 分页获取参数
    GetQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    },
    //获取两个时间的间隔
    getTime(beginTime, endTime) {
        var dateDiff = new Date(endTime).getTime() - new Date(beginTime).getTime(); //时间差的毫秒数
        var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
        //var leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
        //var hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
        //计算相差分钟数
        //var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
        //var minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
        //计算相差秒数
        //var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
        //var seconds = Math.round(leave3 / 1000)
        return "共" + dayDiff + "天 ";
    },
    getNextDate(date, day) {
        var dd = new Date(date);
        dd.setDate(dd.getDate() + day);
        var y = dd.getFullYear();
        var m =
            dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
        var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        return y + "-" + m + "-" + d;
    }
}