<template>
  <div id="home" class="content-box">
    <router-view />
  </div>
</template>

<style scoped>
.content-box {
  width: 100%;
  min-width: 100%;
}
@media screen and (max-width:1440px) {
    .content-box {
        min-width: 100%;
         overflow-x: hidden;
    }
}
@media screen and (max-width:1366px) {
    .content-box {
        min-width: 100%;
         overflow-x: hidden;
    }
}
@media screen and (max-width:1280px) {
    .content-box {
        min-width: 100%;
         overflow-x: hidden;
    }
}
</style>

<script>
export default {
  name: "home",
    mounted() {
    if (this._isMobile()) {
      window.location.href="http://wap.fyrentcar.com/fy/home.html"
    } else {
      console.log("pc端");
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
